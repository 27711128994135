<template>
    <main>
        <div class=" " >
            <div class="card-body ">
                <div class="row">
                    <div class="col-8  mt-3" >
                     <div class="form-group">
                        <label class="form-label">Seleccione rango de fechas</label>
                       
                            <date-picker class="w-100" v-model="range" range valueType="format"></date-picker>
                        </div>
                    </div>
                    <div class="col-4  mt-5">
                        <div class="form-group">
                            <button class="btn brn-icon btn-success" @click="report()"><i class="fa fa-file-excel "></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import registroPaiService from "../../../services/regitroPaiService";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
    components:{
        DatePicker
    },
    data() {
        return {
            range:'',
        }
    },
    methods: {
         report(){
           registroPaiService.reportGeneralVacunas(
                this.range[0],
                this.range[1],
            );
        },
    },
}
</script>
<style scoped>
    .scroll {
    max-height: 400px;
    overflow-y: auto;
}
</style>