<template>
    <main>
        <header class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
            <div class="container-xl px-4">
                <div class="page-header-content pt-4">
                    <div class="row align-items-center justify-content-between">
                        <div class="col-auto mt-4">
                            <h1 class="page-header-title">
                                <div class="page-header-icon">
                                    <i class="far fa-clipboard"></i>
                                </div>
                                Reporte de vacunas
                            </h1>
                            <div class="page-header-subtitle"></div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <br> 
        <div class="container-xl px-4 mt-n15">
            <div class="card">
                <div class="card-header">PAI</div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            <ul class="nav nav-pills flex-column" id="cardPillVertical" role="tablist">
                                <li class="nav-item"><a class="nav-link active" id="overview-pill-vertical" href="#overviewPillVertical" data-bs-toggle="tab" role="tab" aria-controls="overview" aria-selected="true">Consolidado</a></li>
                                <li class="nav-item"><a class="nav-link" id="example-pill-vertical" href="#examplePillVertical" data-bs-toggle="tab" role="tab" aria-controls="example" aria-selected="false">Por usuario</a></li>
                                <li class="nav-item"><a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true"></a></li>
                            </ul>
                        </div>
                        <div class="col-md-9">
                            <div class="tab-content" id="cardPillContentVertical">
                                <div class="tab-pane fade show active" id="overviewPillVertical" role="tabpanel" aria-labelledby="overview-pill-vertical">
                                    <reportGeneral></reportGeneral>
                                </div>
                                <div class="tab-pane fade" id="examplePillVertical" role="tabpanel" aria-labelledby="example-pill-vertical">
                                    <h5 class="card-title"></h5>
                                    <p class="card-text">...</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>   
    </main>
</template>
<script>
import reportGeneral from "./reportGeneral";
export default {
    components: {reportGeneral},
}
</script>